import React, { useState, useMemo } from "react";
import axios from "axios";
import {
  Container,
  TextField,
  Button,
  Typography,
  Grid,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";
import Modal from "../../components/ModalPopUp/Modal";
import ModalMsg from "../../components/ModalPopUp/ModalMsg";

const PlayersForm = ({ display, onDisplay }) => {
  const [formData, setFormData] = useState({
    fullNamePlayer1: "",
    fullNamePlayer2: "",
    emailPlayer1: "",
    emailPlayer2: "",
    phonePlayer1: "",
    phonePlayer2: "",
    cedulaPlayer1: "",
    cedulaPlayer2: "",
    shirtSizePlayer1: "",
    shirtSizePlayer2: "",
    category: "",
    payment: "",
  });
  const [showSecond, setShowSecond] = useState(false);
  const [modalInfo, setModalInfo] = useState({ type: "", text: "" });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        "https://tennis-open-league-api.vercel.app/v1/season/agregar",
        formData
      );
      setFormData({
        fullNamePlayer1: "",
        fullNamePlayer2: "",
        emailPlayer1: "",
        emailPlayer2: "",
        phonePlayer1: "",
        phonePlayer2: "",
        cedulaPlayer1: "",
        cedulaPlayer2: "",
        shirtSizePlayer1: "",
        shirtSizePlayer2: "",
        category: "",
        payment: "",
      });
      if (response) {
        setModalInfo({
          type: "success",
          text: "Gracias por registrarte! <br> Puedes comunicarte al <u>04148532483</u> para enviar tu comprobante de pago o dirígete a la Tienda RS en el C.C. Cerro Verde para entregar el efectivo",
        });
        setShowSecond(true);
      }
    } catch (error) {
      console.error("Error submitting player data:", error);
      setModalInfo({
        type: "error",
        text: "Hubo un error al registrar los datos",
      });
      setShowSecond(true);
    }
  };

  const shirtSizes = ["XS", "S", "M", "L", "XL", "XXL"];


  const isSinglePlayerCategory = useMemo(() => {
    return ["single"].includes(formData.category);
  }, [formData.category]);

  const isFormValid = useMemo(() => {
    if (isSinglePlayerCategory) {
      return (
        formData.fullNamePlayer1.trim() !== "" &&
        formData.emailPlayer1.trim() !== "" &&
        formData.phonePlayer1.trim() !== "" &&
        formData.cedulaPlayer1.trim() !== "" &&
        formData.shirtSizePlayer1.trim() !== "" &&
        formData.category.trim() !== "" &&
        formData.payment.trim() !== ""
      );
    } else {
      return Object.values(formData).every((value) => value.trim() !== "");
    }
  }, [formData, isSinglePlayerCategory]);

  return (
    <Modal
      show={display}
      modalStyle={{ backgroundColor: "white", padding:'20px' }}
      extraCloseStyles={{color: '#03438a', fontWeight:'bold', fontSize:'1.2em', marginRight:'10px', marginTop:'10px'}}
      onClose={() => {
        onDisplay(false);
      }}
    >
      <Container maxWidth="md" style={{paddingBottom:'30px'}}>
        <Typography variant="h4" gutterBottom color={'#03438a'}>
          Planilla de Registro
        </Typography>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            {Object.entries(formData).map(([name, value]) => {
              if (name === "category" || name === "payment") {
                return null;
              }
              if (isSinglePlayerCategory && name.includes("Player2")) {
                return null;
              }
              if (name === "shirtSizePlayer1" || name === "shirtSizePlayer2") {
                return (
                  <Grid item xs={12} sm={6} key={name}>
                    <FormControl fullWidth margin="normal" required>
                      <InputLabel id={`${name}-label`}>
                        {name === "shirtSizePlayer1"
                          ? "Talla de franela (Jugador 1)"
                          : "Talla de franela (Jugador 2)"}
                      </InputLabel>
                      <Select
                        labelId={`${name}-label`}
                        id={name}
                        name={name}
                        value={value}
                        label={
                          name === "shirtSizePlayer1"
                            ? "Talla de franela (Jugador 1)"
                            : "Talla de franela (Jugador 2)"
                        }
                        onChange={handleChange}
                      >
                        {shirtSizes.map((size) => (
                          <MenuItem key={size} value={size}>
                            {size}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                );
              }
              return (
                <Grid item xs={12} sm={6} key={name}>
                  <TextField
                    name={name}
                    label={
                      {
                        fullNamePlayer1: "Nombre completo (Jugador 1)",
                        fullNamePlayer2: "Nombre completo (Jugador 2)",
                        emailPlayer1: "Correo Electronico (Jugador 1)",
                        emailPlayer2: "Correo Electronico (Jugador 2)",
                        phonePlayer1: "Telefono (Jugador 1)",
                        phonePlayer2: "Telefono (Jugador 2)",
                        cedulaPlayer1: "Cedula (Jugador 1)",
                        cedulaPlayer2: "Cedula (Jugador 2)",
                      }[name]
                    }
                    fullWidth
                    margin="normal"
                    value={value}
                    onChange={handleChange}
                    required
                  />
                </Grid>
              );
            })}
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth margin="normal" required>
                <InputLabel id="category-label">Categoria</InputLabel>
                <Select
                  labelId="category-label"
                  id="category"
                  name="category"
                  value={formData.category}
                  label="Categoria"
                  onChange={handleChange}
                >
                  <MenuItem value="11masc">+11 Masculino</MenuItem>
                  <MenuItem value="9masc">+9 Masculino</MenuItem>
                  <MenuItem value="7masc">+7 Masculino</MenuItem>
                  <MenuItem value="5masc">+5 Masculino</MenuItem>
                  <MenuItem value="11fem">+11 Femenino</MenuItem>
                  <MenuItem value="9fem">+9 Femenino</MenuItem>
                  <MenuItem value="7fem">+7 Femenino</MenuItem>
                  <MenuItem value="11mixto">+11 Mixto</MenuItem>
                  <MenuItem value="9mixto">+9 Mixto</MenuItem>
                  <MenuItem value="single">Single Open</MenuItem>
                  <MenuItem value="12juvfem">12 Juvenil Femenino</MenuItem>
                  <MenuItem value="12juvmasc">12 Juvenil Masculino</MenuItem>
                  <MenuItem value="14juvfem">14 Juvenil Femenino</MenuItem>
                  <MenuItem value="14juvmasc">14 Juvenil Masculino</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth margin="normal" required>
                <InputLabel id="payment-label">Forma de pago</InputLabel>
                <Select
                  labelId="payment-label"
                  id="payment"
                  name="payment"
                  value={formData.payment}
                  label="Forma de pago"
                  onChange={handleChange}
                >
                  <MenuItem value="cash">Efectivo</MenuItem>
                  <MenuItem value="zelle">Zelle</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                fullWidth
                disabled={!isFormValid}
              >
                Registarme ahora
              </Button>
            </Grid>
          </Grid>
        </form>
        <Modal
          show={showSecond}
          onClose={() => {
            setShowSecond(false);
            onDisplay(false);
          }}
        >
          <ModalMsg typeModal={modalInfo.type} textModal={modalInfo.text} />
        </Modal>
      </Container>
    </Modal>
  );
};

export default PlayersForm;
