import React from "react";
import { Box, Typography } from "@mui/material";
import {
  LongTextInicio,
  Slogan,
  longTextStyles,
  longTextStylesBold,
  tennisOpenLeagueText,
} from "../helpers/constants";
import GradientButton from "../components/GradientButton";
//import mainInicioImg from "../assets/MujerPortada.png";
import mainInicioImg from "../assets/TennisFest.svg";
import Menu from "../components/Menu";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import ScrollTop from "../components/ScrollTop";
import Fab from "@mui/material/Fab";
import { useNavigate } from "react-router-dom";
import PlayersForm from "./season2025/form2025I";

const Inicio = () => {
  const mainInicioImgStyles =
    window.innerWidth < 768
      ? { width: "100%", paddingTop: "30px" }
      : { width: "65%" };
  const buttonExtraStyles =
    window.innerWidth < 768
      ? { fontSize: "0.5rem", padding: "5px 15px" }
      : null;
  const navigate = useNavigate();
  const [show, setShow] = React.useState(false)
  return (
    <React.Fragment>
      <Box
        id="Inicio"
        sx={{
          position: "relative",
          padding: { md: "0 100px 30px 100px", xs: "50px 50px 30px 50px" },
          width: { xs: "-webkit-fill-available", md: "auto" },
          overflowX: "hidden",
          backgroundColor: "#000000",
        }}
      >
        <Menu />
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <img
            src={mainInicioImg}
            alt="Tennis Open League"
            style={{...mainInicioImgStyles, cursor:'pointer'}}
            onClick={() => setShow(true)}
          />
        </Box>
        <PlayersForm display={show} onDisplay={setShow}/>
        <Box
          sx={{
            display: "flex",
            flexDirection: { md: "column-reverse", xs: "column-reverse" },
            marginTop: { md: "40px", xs: "40px" },
            alignItems: { md: "center" },
          }}
        >
          <Typography
            sx={{
              fontFamily: "MontserratBold",
              color: "#ffffff",
              order: 1,
              marginBottom: "20px",
              textAlign: "center",
              fontSize: { md: "1.5rem", xs: "1rem" },
            }}
          >
            {tennisOpenLeagueText}
          </Typography>
          <Box
            sx={{
              display: "flex",
              height: "10%",
              width: { md: "100%", xs: "100%" },
              justifyContent: "center",
              paddingBottom: { md: "20px" },
            }}
          >
            <GradientButton
              text="Inscribir Equipo"
              typeButton="orange"
              extraStyles={buttonExtraStyles}
              onClick={() => navigate("/iniciarsesion")}
            />
            <a href="#Contáctanos">
              <GradientButton
                text="Contáctanos"
                typeButton="transparentOrange"
                extraStyles={{ marginLeft: "20px", ...buttonExtraStyles }}
              />
            </a>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: { md: "100%", xs: "100%" },
              alignItems: "center",
              height: "fit-content",
            }}
          >
            <Box
              sx={{
                marginLeft: { md: 0, xs: 0 },
                textAlign: { md: "center", xs: "center" },
                width: { md: "60%" },
              }}
            >
              <Typography
                sx={{
                  ...longTextStyles,
                  paddingTop: 0,
                  marginLeft: { md: 0, xs: 0 },
                  textAlign: { md: "center", xs: "center" },
                  width: { md: "100%" },
                }}
              >
                {LongTextInicio.slice(0, 34)}
                <strong>{LongTextInicio.slice(34, 52)}</strong>
                {LongTextInicio.slice(52, LongTextInicio.length)}
              </Typography>
              <Typography
                sx={{
                  ...longTextStylesBold,
                  width: "100%",
                  padding: "0 0 40px 0",
                }}
              >
                {Slogan}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
      <ScrollTop>
        <Fab color="secondary" size="small" aria-label="scroll back to top">
          <KeyboardArrowUpIcon />
        </Fab>
      </ScrollTop>
    </React.Fragment>
  );
};
export default Inicio;
