import React, { useEffect, useRef } from "react";
import Button from "./Button";
import styles from "./modal.module.css";

const Modal = ({ modalStyle, children, show, onClose, backdropStyle, extraCloseStyles }) => {
  const modalRef = useRef(null);
  useEffect(() => {
    if (show) {
      modalRef.current.classList.add(styles.visible);
    } else {
      modalRef.current.classList.remove(styles.visible);
    }
  }, [show]);
  return (
    <React.Fragment>
      <div
        ref={modalRef}
        style={backdropStyle}
        className={`${styles.modal__wrap}`}
      >
        <div style={modalStyle} className={styles.modal}>
          <Button
            onClick={onClose}
            style={{
              width: 30,
              height: 30,
              position: "absolute",
              top: 0,
              right: 0,
              backgroundColor: "transparent",
              border: "none",
              color: "#ffffff",
              fontSize: "1rem",
              cursor: "pointer",
              ...extraCloseStyles
            }}
            className={styles.close__btn}
          >
            X
          </Button>
          {children}
        </div>
      </div>
    </React.Fragment>
  );
};

export default Modal;
