import React, { useEffect, useState } from "react";
import { rolesList, sexoList } from "../../helpers/constants";
import { Box, Typography } from "@mui/material";
import { Form, Input, Button, Dropdown, Radio } from "semantic-ui-react";
import axios from "axios";
import { categories, categoriesPlayer } from "../../helpers/dbConstants";
import imgGestionarUsuarios from "../../assets/dashboard/icono_vista_gestionar-usuarios.svg";
import Modal from "../../components/ModalPopUp/Modal";
import ModalMsg from "../../components/ModalPopUp/ModalMsg";
import GradientButton from "../../components/GradientButton";
import { useNavigate } from "react-router-dom";
import env from "react-dotenv";
import { convertDate, getCategoryInfo } from "../../helpers/commonFunctions";
import "./usuarios.css";

const GestionarUsuarios = () => {
  const [userData, setUserData] = useState();
  const [userNewData, setUserNewData] = useState();
  const [show, setShow] = useState(false);
  const [teamsData, setTeamsData] = useState([]);
  const [userId, setUserId] = useState("");
  const [canEdit, setCanEdit] = useState(false);
  const [isNewUser, setIsNewUser] = useState(false);
  const [isOldUser, setIsOldUser] = useState(false);
  const [modalInfo, setModalInfo] = useState({ type: "", text: "" });
  const [radioValue, setRadioValue] = useState();
  const navigate = useNavigate();

  useEffect(() => {
    getTeamsData();
  }, []);

  const getUserData = async () => {
    await axios
      .get(`https://tennis-open-league-api.vercel.app/v1/user/getuserid/${userId}`)
      .then((res) => {
        setUserData({ ...res.data });
      })
      .catch((error) => {
        setModalInfo({
          type: "error",
          text: "Usuario no encontrado!",
        });
        setShow(true);
        setUserData({});
      });
  };

  const actualizarRanking = async () => {
    await axios
      .get(`https://tennis-open-league-api.vercel.app/v1/actualizarrankinggeneral`)
      .then((res) => {
        if (res.status === 200) {
          setModalInfo({
            type: "success",
            text: "Ranking actualizado correctamente!",
          });
          setShow(true);
        }
      })
      .catch((error) => {
        setModalInfo({
          type: "error",
          text: "Hubo un problema",
        });
        setShow(true);
      });
  };

  const handleChange = (e, { name, value }) => {
    isNewUser && setUserNewData({ ...userNewData, [name]: value });
    isOldUser && setUserData({ ...userData, [name]: value });
  };

  const getTeamsData = async () => {
    await axios
      .get(`https://tennis-open-league-api.vercel.app/v1/teams/getteams`)
      .then((res) => setTeamsData(res.data))
      .catch((error) => console.log(error));
  };
  const teamOptions =
    teamsData &&
    teamsData.map((team) => ({
      key: team.id,
      text: `${team.name} - ${getCategoryInfo(team.idcategory)}`,
      value: team.id,
    }));

  const teamOptionsAlt =
    teamsData &&
    teamsData.map((team) => ({
      key: team.id,
      text: `${team.name} - ${getCategoryInfo(team.idcategory)}`,
      value: team.id,
    }));
  teamOptionsAlt.push({ key: null, text: "Ninguno", value: null });

  const handleRadioChange = () => {
    if (isOldUser && userData) {
      setUserData({ ...userData, active: !userData.active });
    }
    if (isNewUser && userNewData) {
      setUserData({ ...userNewData, active: !userNewData.active });
    }
  };

  const handleAgregarUsuario = async (e) => {
    const teamcategoryData = teamsData && teamsData.find(team => team?.id === userNewData.idteam)?.idcategory
    const userPayload = {
      name: userNewData.name,
      email: userNewData.email,
      password: userNewData.password,
      role: userNewData.role,
      dateOfBirth: userNewData.dateofbirth,
      cedula: userNewData.cedula,
      active: true,
      idTeam: userNewData.idteam,
      idCategory: userNewData.idcategory,
      sexo: userNewData.sexo,
      telefono: userNewData.telefono,
      persona_contacto: userNewData.persona_contacto,
      tlf_persona_contacto: userNewData.tlf_persona_contacto,
      idteam_2: userNewData.idteam_2,
      idteam_3: userNewData.idteam_3,
      teamcategory: teamcategoryData
    };
    await axios
      .post(`https://tennis-open-league-api.vercel.app/v1/user/create`, userPayload)
      .then((res) => {
        if (res.status === 200) {
          setModalInfo({
            type: "success",
            text: "Usuario creado correctamente!",
          });
          setShow(true);
          setUserNewData({});
        }
      })
      .catch((error) => {
        console.log(error);
        setModalInfo({
          type: "error",
          text: "Hubo un error al tratar de crear el usuario",
        });
        setShow(true);
      });
  };

  const handleModificarUsuario = async () => {
    const teamcategoryData = teamsData && teamsData.find(team => team?.id === userData.idteam)?.idcategory
    const userPayload = {
      name: userData.name,
      email: userData.email,
      password: userData.password,
      role: userData.role,
      dateOfBirth: userData.dateofbirth,
      cedula: userData.cedula,
      active: true,
      idTeam: userData.idteam,
      idCategory: userData.idcategory,
      sexo: userData.sexo,
      telefono: userData.telefono,
      persona_contacto: userData.persona_contacto,
      tlf_persona_contacto: userData.tlf_persona_contacto,
      idteam_2: userData.idteam_2,
      idteam_3: userData.idteam_3,
      teamcategory: teamcategoryData
    };
    await axios
      .put(`https://tennis-open-league-api.vercel.app/v1/user/update`, userPayload)
      .then((res) => {
        if (res.status === 200) {
          setModalInfo({
            type: "success",
            text: "Datos modificados correctamente!",
          });
          setShow(true);
        }
      })
      .catch((error) => {
        console.log(error);
        setModalInfo({
          type: "error",
          text: "Hubo un error al tratar de modificar los datos",
        });
        setShow(true);
      });
  };

  return (
    <Box
      p={2}
      id="gestionar-usuarios"
      sx={{
        display: "flex",
        flexDirection: "column",
        backgroundColor: "#03438a",
        minHeight: "100vh",
        fontFamily: "Montserrat",
        width: { md: "80%", xs: "80%" },
        alignItems: "start",
        borderRadius: "7px",
        border: "1px solid #ffffff",
        boxShadow: "4px 4px 4px #03435a",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: { md: "row", xs: "column" },
          backgroundColor: "#03438a",
          width: "100%",
          borderRadius: "3px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: { md: "row", xs: "column" },
            width: { md: "50%", xs: "100%" },
            textAlign: { md: "left", xs: "center" },
            placeContent: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: { md: "40%", xs: "100%" },
              alignItems: "center",
              paddingTop: { md: 0, xs: "20px" },
              paddingLeft: { md: "10px", xs: 0 },
            }}
          >
            <img
              src={imgGestionarUsuarios}
              alt="Tennis Open League"
              style={{
                width: window.innerWidth < 768 ? "100px" : "150px",
                margin: "0 auto",
                height: "150px",
              }}
            />
          </Box>
          <Box
            p={2}
            sx={{ width: { md: "60%", xs: "100%" }, color: "#ffffff" }}
          >
            <Typography
              sx={{
                fontFamily: "MontserratBold",
                fontSize: { md: "1.25rem", xs: "1rem" },
                marginBottom: "15px",
              }}
            >
              Gestionar Usuarios
            </Typography>
            <Typography sx={{ fontFamily: "Montserrat" }}>
              Escriba los campos necesarios para agregar o editar usuarios
            </Typography>
          </Box>
        </Box>
        <Box
          p={2}
          sx={{
            display: "flex",
            width: "50%",
            flexDirection: "column",
            alignItems: "flex-end",
            justifyContent: "flex-start",
          }}
        >
          <GradientButton
            text="VOLVER"
            typeButton="transparent"
            onClick={() => navigate("/dashboard")}
            extraStyles={{ marginTop: "30px", padding: "5px" }}
          />
        </Box>
      </Box>
      <Box
        p={2}
        sx={{
          display: "flex",
          flexDirection: "column",
          textAlign: "left",
          color: "#ffffff",
          maxWidth: "-webkit-fill-available",
          width: "100%",
        }}
      >
        <Box sx={{ padding: "10px", display: "flex" }}>
          <Form.Field
            id="form-input-control-buscar-cedula"
            control={Input}
            label="Jugador: &nbsp;&nbsp;"
            name="jugador"
            placeholder="Introduce la cedula"
            value={userId}
            required
            disabled={isNewUser}
            onChange={(e) => setUserId(e.target.value)}
          />
          <Button
            type="submit"
            disabled={isNewUser}
            style={{
              backgroundColor: "#ffa436",
              border: "none",
              padding: "4px 20px",
              marginLeft: "20px",
              boxShadow: "3px 3px 3px #03435a",
              cursor: "pointer",
              borderRadius: "5px",
            }}
            onClick={getUserData}
          >
            <p
              style={{
                color: "#ffffff",
              }}
            >
              Buscar
            </p>{" "}
          </Button>
        </Box>
        <Box>
          <Form className="contact-form-gestionar-usuarios">
            <Form.Field
              id="form-input-control-name"
              control={Input}
              label="Nombre completo"
              name="name"
              value={
                userData && userData.name
                  ? userData.name
                  : userNewData && userNewData.name
                  ? userNewData.name
                  : ""
              }
              required
              disabled={!canEdit}
              onChange={handleChange}
            />{" "}
            <Form.Field
              id="form-input-control-password"
              control={Input}
              type="text"
              label="Contraseña"
              name="password"
              disabled={!canEdit}
              value={
                userData && userData.password
                  ? userData.password
                  : userNewData && userNewData.password
                  ? userNewData.password
                  : ""
              }
              onChange={handleChange}
              required
            />
            <Form.Field
              id="form-input-control-email"
              control={Input}
              label="Correo electronico"
              name="email"
              disabled={!canEdit}
              value={
                userData && userData.email
                  ? userData.email
                  : userNewData && userNewData.email
                  ? userNewData.email
                  : ""
              }
              required
              onChange={handleChange}
            />
            <Form.Field
              id="form-input-control-cedula"
              control={Input}
              label="N de Cédula"
              name="cedula"
              disabled={!canEdit}
              value={
                userData && userData.cedula
                  ? userData.cedula
                  : userNewData && userNewData.cedula
                  ? userNewData.cedula
                  : ""
              }
              required
              onChange={handleChange}
            />
            <Form.Field
              id="form-input-control-fecha'nacimiento"
              control={Input}
              label="Fecha de nacimiento"
              name="dateofbirth"
              disabled={!canEdit}
              value={userData?.dateofbirth}
              required={isNewUser}
              type="date"
              onChange={handleChange}
            />
            <Form.Field
              id="form-input-control-telefono"
              control={Input}
              label="Celular"
              name="telefono"
              disabled={!canEdit}
              value={
                userData && userData.telefono
                  ? userData.telefono
                  : userNewData && userNewData.telefono
                  ? userNewData.telefono
                  : ""
              }
              required={isNewUser}
              onChange={handleChange}
            />
            <Dropdown
              placeholder="Categoria"
              name="idcategory"
              labeled
              search
              selection
              disabled={!canEdit}
              value={
                userData && userData.idcategory
                  ? userData.idcategory
                  : userNewData && userNewData.idcategory
                  ? userNewData.idcategory
                  : ""
              }
              options={categoriesPlayer}
              onChange={handleChange}
            />
            <Dropdown
              placeholder="Equipo"
              name="idteam"
              labeled
              search
              selection
              value={
                userData && userData.idteam
                  ? userData.idteam
                  : userNewData && userNewData.idteam
                  ? userNewData.idteam
                  : ""
              }
              disabled={!canEdit}
              options={teamOptions}
              onChange={handleChange}
            />
            <Dropdown
              placeholder="Rol"
              search
              selection
              disabled={!canEdit}
              options={rolesList}
              value={
                userData && userData.role
                  ? userData.role
                  : userNewData && userNewData.role
                  ? userNewData.role
                  : ""
              }
              name="role"
              onChange={handleChange}
            />
            <Dropdown
              placeholder="Equipo 2"
              name="idteam_2"
              labeled
              search
              selection
              value={
                userData && userData.idteam_2
                  ? userData.idteam_2
                  : userNewData && userNewData.idteam_2
                  ? userNewData.idteam_2
                  : ""
              }
              disabled={!canEdit}
              options={teamOptionsAlt}
              onChange={handleChange}
            />
            <Dropdown
              placeholder="Sexo"
              search
              selection
              disabled={!canEdit}
              options={sexoList}
              value={
                userData && userData.sexo
                  ? userData.sexo
                  : userNewData && userNewData.sexo
                  ? userNewData.sexo
                  : ""
              }
              name="sexo"
              onChange={handleChange}
            />
            <Dropdown
              placeholder="Equipo 3"
              name="idteam_3"
              labeled
              search
              selection
              value={
                userData && userData.idteam_3
                  ? userData.idteam_3
                  : userNewData && userNewData.idteam_3
                  ? userNewData.idteam_3
                  : ""
              }
              disabled={!canEdit}
              options={teamOptionsAlt}
              onChange={handleChange}
            />
            <Radio
              id="gestionar-usuarios-jugador-activo"
              label="Jugador activo"
              name="active"
              disabled={!canEdit}
              toggle
              checked={
                userData && userData.active
                  ? userData.active
                  : userNewData && userNewData.active
                  ? userNewData.active
                  : false
              }
              onChange={handleRadioChange}
            />
            <Box
              sx={{
                width: "100% !important",
                display: "flex",
                justifyContent: "flex-end",
                paddingRight: "25px",
              }}
            >
              <Button
                type="submit"
                style={{
                  backgroundColor: "#ffa436",
                  border: "none",
                  padding: "10px",
                  boxShadow: "3px 3px 3px #03435a",
                  cursor: "pointer",
                  borderRadius: "5px",
                }}
                disabled={isOldUser}
                onClick={() => {
                  setIsOldUser(false);
                  if (canEdit && isNewUser) {
                    setCanEdit(false);
                    setIsNewUser(false);
                    setUserNewData({});
                  } else {
                    setCanEdit(true);
                    setIsNewUser(true);
                    setUserData({});
                    setUserId("");
                  }
                }}
              >
                <p
                  style={{
                    color: "#ffffff",
                  }}
                >
                  {canEdit && isNewUser ? "Cancelar" : "Agregar"}
                </p>{" "}
              </Button>
              <Button
                type="submit"
                disabled={isNewUser}
                style={{
                  backgroundColor: "#ffa436",
                  border: "none",
                  padding: "10px",
                  boxShadow: "3px 3px 3px #03435a",
                  cursor: "pointer",
                  borderRadius: "5px",
                  marginRight: "15px",
                  marginLeft: "15px",
                }}
                onClick={() => {
                  if (userData && Object.keys(userData).length > 0) {
                    setIsNewUser(false);
                    if (canEdit && isOldUser) {
                      setCanEdit(false);
                      setIsOldUser(false);
                    } else {
                      setCanEdit(true);
                      setIsOldUser(true);
                    }
                  }
                }}
              >
                <p
                  style={{
                    color: "#ffffff",
                  }}
                >
                  {canEdit && isOldUser ? "Cancelar" : "Editar"}
                </p>{" "}
              </Button>
              <Button
                type="submit"
                style={{
                  backgroundColor: "#ffa436",
                  border: "none",
                  padding: "10px",
                  boxShadow: "3px 3px 3px #03435a",
                  cursor: "pointer",
                  borderRadius: "5px",
                }}
                onClick={(e) => {
                  e.preventDefault();
                  if (isNewUser && userNewData) {
                    handleAgregarUsuario();
                    setCanEdit(false);
                    setIsNewUser(false);
                  }
                  if (isOldUser && userData) {
                    handleModificarUsuario();
                    setCanEdit(false);
                    setIsOldUser(false);
                  }
                }}
              >
                <p
                  style={{
                    color: "#ffffff",
                  }}
                >
                  Guardar
                </p>{" "}
              </Button>
            </Box>
          </Form>
        </Box>
        <Box paddingTop={8}>
        <Button
          type="submit"
          style={{
            backgroundColor: "#ffa436",
            border: "none",
            padding: "10px",
            boxShadow: "3px 3px 3px #03435a",
            cursor: "pointer",
            borderRadius: "5px",
          }}
          onClick={actualizarRanking}
        >
          <p
            style={{
              color: "#ffffff",
            }}
          >
            Actualizar Ranking Jugadores
          </p>{" "}
        </Button>
        </Box>
      </Box>
      <Modal
        show={show}
        onClose={() => {
          setShow(false);
        }}
      >
        <ModalMsg typeModal={modalInfo.type} textModal={modalInfo.text} />
      </Modal>
    </Box>
  );
};

export default GestionarUsuarios;
